var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agreement" }, [
    _c("h1", [_vm._v("用户协议")]),
    _c("p", { staticStyle: { "font-size": "14px" } }, [
      _vm._v("欢迎您使用无限立方软件及服务！")
    ]),
    _vm._m(0),
    _c("h3", [_vm._v("一、无限立方服务说明")]),
    _c("p", [_vm._v("1.服务宗旨：让设计、印刷、营销更简单。")]),
    _vm._m(1),
    _c("p", [
      _vm._v(
        " 3. 服务内容：无限立方是一款简单易用、功能强大的线上图形设计工具，为设计小白创作而生。通过简单的拖拉拽操作就可以轻松设计出精美的图片，让人人都可以轻松做出精美设计，满足运营、新媒体小编、文案策划、行政人事等不同岗位的高频设计需求；也是一款营销工具，各种营销活动助力企业拉新、转化、促活；同时在线印刷服务提供一站式印刷解决方案，品类丰富，便捷下单，物美价廉，闪电物流，售后保证。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 4. 服务平台：指由公司运营的为用户提供展示或销售服务的网络平台，域名为wx.taiheiot.com以及公司启用的其他域名。 "
      )
    ]),
    _c("p", [_vm._v("5.服务许可范围：")]),
    _vm._m(2),
    _c("p", [
      _vm._v(
        " （2） 本协议未明示授权的其他一切权利仍由无限立方保留，您在行使这些权利时须另外取得无限立方的书面许可。无限立方未行使或执行本协议任何权利或规定，不构成对该权利的放弃。 "
      )
    ]),
    _c("h3", [_vm._v("二、用户账号")]),
    _c("p", [_vm._v("1. 账号注册")]),
    _c("p", [
      _vm._v(
        " 1.1 为了使用无限立方服务，用户需要注册一个账号。您需提供个人真实信息完成账号注册和密码设置，或通过无限立方认可的第三方账号（如微信账号、QQ账号等）进行注册，为保证您享用的平台服务安全有效且不断优化，您同意授权无限立方对您的个人信息进行验证和合理使用。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 1.2 用户不得将注册在自己名下的账号授予他人使用，授予他人使用的情形包括但不限于用户与他人共享账号、向他人出借账号或出售使用权限等。用户也不得使用注册在自己名下的账号协助他人获得无限立方的付费服务。用户违反本协议的约定转借账号或协助盗取的，无限立方有权立即撤销该用户使用无限立方服务的许可、不予退还该用户剩余的付费服务期限相应的对价并保留向该用户追偿的权利。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 1.3 您需对您提供的注册资料的真实性、准确性、合法性负责，并及时更新您的资料。若您提供任何违法、不道德或无限立方认为不适合在无限立方上展示的资料，或是无限立方有理由怀疑你的资料属于恶意程序或恶意操作，无限立方有权暂停或终止您的账号，并拒绝您于现在和未来使用本服务之全部或任何部分。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 1.4 无限立方无须对任何用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、正确性、完整性、适用性及/或是否为最新资料的责任。 "
      )
    ]),
    _vm._m(3),
    _c("p", [_vm._v("2. 用户账号安全")]),
    _c("p", [
      _vm._v(
        " 完成本服务的注册程序并成功注册之后，您可使用您的手机号和密码，登录到您在无限立方的账号。保护您的账号安全，是您的责任。您同意： "
      )
    ]),
    _c("p", [
      _vm._v(
        " （1） 您应对所有使用您的密码及账号的活动负完全的责任，包括但不限于您在无限立方中进行的任何数据修改、款项支付、图片设计等行为。您应高度重视对账号与密码的保密，在任何情况下不向他人透露账号及密码。您的无限立方账号遭到未获授权的使用，或者发生其它任何安全问题时，您将立即通知无限立方。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " （2） 如果您未保管好自己的账号和密码，因此而产生的任何损失或损害，无限立方无法也不承担任何责任。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " （3） 您有责任维护个人账号、密码的安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任。如果您未保管好自己的账号和密码而对您、无限立方或第三方造成的损害，您将负全部责任。 "
      )
    ]),
    _c("h3", [_vm._v("三、用户个人信息保护")]),
    _c("p", [
      _vm._v(
        " 1. 保护用户个人信息是无限立方的一项基本原则，无限立方将会采取合理的措施保护用户的个人信息。"
      ),
      _c("strong", [
        _vm._v(
          "您知悉并同意，为方便您使用本平台相关服务，本平台将按照本协议及《隐私策略》的规定收集、使用、存储和分享您的个人信息与必要信息，包括但不限于您的登记资料、作图记录、作图结果等操作记录。除法律法规规定、"
        ),
        _c(
          "a",
          {
            attrs: { href: _vm.$url + "/#/PrivacyAgreement", target: "_blank" }
          },
          [_vm._v("《隐私策略》")]
        ),
        _vm._v(
          "约定或另有约定的情形外，未经您的许可无限立方不会获取、存储或向第三方公开、透露您的个人信息。"
        )
      ])
    ]),
    _c("p", [
      _vm._v(
        " 2. 您知悉并确认，您在注册账号或使用本平台的过程中，需要提供真实的身份信息，无限立方将根据国家法律法规的要求，采用移动电话等进行真实身份信息认证。若您提供的信息不真实、不完整，则无法使用本平台或在使用过程中受到限制，由此产生的不利后果，由您自行承担。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 3. 无限立方将运用各种安全技术和程序、建立完善的管理制度来保护您的个人信息，防止您的信息被不当使用或遭受未经授权的访问、使用或披露。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 4. 未经您的同意，无限立方不会向其他任何公司、组织和个人披露您的个人信息，除非： "
      )
    ]),
    _c("p", [_vm._v("（1）相关法律法规或司法机关、行政机关要求；")]),
    _c("p", [_vm._v("（2）为完成合并、分立、收购或资产转让而转移；")]),
    _c("p", [_vm._v("（3）为提供您要求的服务所必须；")]),
    _vm._m(4),
    _c("h3", [_vm._v("四、用户行为规范")]),
    _c("p", [_vm._v("1. 信息内容规范")]),
    _c("p", [
      _vm._v(
        " 您应对使用无限立方平台及服务发布信息内容的真实性、合法性负责。如需获得该信息内容权利人或任何有权第三方的事先授权或批准的，应事先获得该等授权或批准；一旦您发布某项信息内容，将被视为您已事先获得该等授权或批准。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 1.1 本条所述信息内容是指用户利用无限立方制作、下载、使用、发布、传播的任何内容，包括但不限于用户的昵称、头像、用户说明等注册信息，或您使用无限立方生成的文字、图片、音频、视频等，以及其他使用无限立方所产生的内容。 "
      )
    ]),
    _vm._m(5),
    _c("p", [_vm._v("（1）反对宪法所确定的基本原则的；")]),
    _c("p", [
      _vm._v(
        " （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一，损害国家荣誉和利益的； "
      )
    ]),
    _c("p", [_vm._v("（3）煽动民族仇恨、民族歧视、破坏民族团结的；")]),
    _c("p", [_vm._v("（4）破坏国家宗教政策，宣扬邪教和封建迷信的；")]),
    _c("p", [_vm._v("（5）散布谣言，扰乱社会秩序，破坏社会稳定的；")]),
    _c("p", [
      _vm._v("（6）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；")
    ]),
    _c("p", [_vm._v("（7）侮辱或者诽谤他人，侵害他人合法权利的；")]),
    _c("p", [
      _vm._v(
        " （8）含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （9）侵犯他人隐私、个人信息、著作权、商标权等知识产权或合法权利的内容； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （10）散布烟草、酒精、药物、整容、医疗保健品类的商业广告，或类似的商业招揽信息，散步过度营销信息及垃圾信息； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （11）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的或含有无限立方认为不适合展示的内容。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 1.3 您理解并同意，无限立方旨在推出多元化、多样式、多种类字体、素材供您使用，但您在体验过程中，仍需遵守法律规定及网站约定。 "
      )
    ]),
    _vm._m(6),
    _c("p", [
      _vm._v(
        " （1）使用或者变相使用中华人民共和国的国旗、国歌、国徽，军旗、军歌、军徽； "
      )
    ]),
    _c("p", [
      _vm._v("（2）使用或者变相使用国家机关、国家机关工作人员的名义或者形象；")
    ]),
    _c("p", [
      _vm._v("（3）使用“国家级”“最高级”“最佳”等违反广告法规定的用语；")
    ]),
    _c("p", [_vm._v("（4）其他法律法规规定不得用于广告的情形。")]),
    _vm._m(7),
    _c("p", [
      _vm._v(
        " （1）使用或者变相使用中华人民共和国、外国、政府间国际组织的国旗、国歌、国徽，军旗、军歌、军徽、勋章； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （2）使用或变相使用中央国家机关的名称、标志、所在地特定地点的名称或者标志性建筑物的名称、图形； "
      )
    ]),
    _c("p", [_vm._v("（3）使用县级以上行政区划的地名或公众知晓的外国地名；")]),
    _c("p", [
      _vm._v("（4）使用无限立方或字体提供方禁止用于本条用途的字体、字库；")
    ]),
    _c("p", [_vm._v("（5）其他法律法规规定不得用于本条用途的情形。")]),
    _c("p", [
      _vm._v(
        " 1.3.3 若无限立方为您提供的图片中已包含国旗、国徽、军旗、军徽等，您禁止以任何方式毁损、玷污、涂划、践踏，否则依法追究法律责任由您自行承担。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 1.3.4 若无限立方为您提供的模板中包含人物肖像，您不得以冒犯或任何不道德方式使用模板中的人物肖像，如色情、整容等诽谤或带有其他非法、不道德内容的用途。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 1.3.5 无限立方将在电商类素材、海报、模板中使用电商平台LOGO、品牌标识等，以方便电商类用户使用，若您不是该电商平台的电商类用户或您未参加LOGO、品牌标识对应的电商活动，您不得使用上述素材、海报、模板，若您必须要使用，须将LOGO、品牌标识删除，否则引发的责任和损失由您自行承担。 "
      )
    ]),
    _vm._m(8),
    _vm._m(9),
    _c("p", [_vm._v("2. 软件使用规范")]),
    _vm._m(10),
    _c("p", [
      _vm._v(
        " （1）使用未经无限立方提供或认可的第三方软件、插件、外挂、系统、设备等登录或使用本服务。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " （2）对无限立方拥有知识产权的内容进行销售、出租、出借、复制、修改、链接、转载、汇编、发表、出版等； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （3）对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （4）未经合法授权而截获、篡改、收集、储存或删除他人个人信息、站内邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （5）对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经无限立方授权的第三方工具/服务接入本软件和相关系统； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （6）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （7）通过非无限立方开发、授权的第三方软件、插件、外挂、系统，登录或使用无限立方平台及服务，或制作、发布、传播上述工具； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （8）自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰； "
      )
    ]),
    _c("p", [
      _vm._v(
        " （9）使用任何方式（包括但不限于第三方软件、插件、外挂、系统、设备等）对无限立方进行干扰、破坏、修改或实施其他任何危害网络安全的活动； "
      )
    ]),
    _c("p", [
      _vm._v("（10）其他违反法律法规、政策或未经无限立方明示授权的行为；")
    ]),
    _c("p", [
      _vm._v(
        " 2.2除本协议约定外，无限立方还将在服务平台内以各种形式提醒用户规范使用无限立方提供的各项服务，您须遵守。您已认可无限立方未对用户的使用行为进行全面控制，您使用任何内容时，包括依赖前述内容之正确性、完整性或合法性时，您同意将自行加以判断并承担所有风险，无限立方在法律允许的范围内不对您因前述风险而导致的任何损失或损害承担责任。在任何情况下，若无限立方有理由认为您的任何行为违反或可能违反上述约定的，无限立方可独立进行判断并处理，且有权在未经通知的情况下终止向您提供服务，并追究相关责任。若您违反本协议约定使用无限立方提供的服务而被第三方追责的，由您自行承担全部责任。 "
      )
    ]),
    _c("h3", [_vm._v("五、服务的中断和终止")]),
    _vm._m(11),
    _vm._m(12),
    _vm._m(13),
    _c("h3", [_vm._v("六、违约处理")]),
    _vm._m(14),
    _vm._m(15),
    _c("p"),
    _c("p", [
      _vm._v(
        " 3. 您对本协议内容有异议，或对无限立方提供的服务不满意，可行使如下权利： "
      )
    ]),
    _c("p", [_vm._v("（1）停止使用无限立方账号服务；")]),
    _vm._m(16),
    _c("h3", [_vm._v("七、广告")]),
    _vm._m(17),
    _vm._m(18),
    _c("h3", [_vm._v("八、第三方产品及服务")]),
    _vm._m(19),
    _vm._m(20),
    _c("h3", [_vm._v("九、增值服务")]),
    _vm._m(21),
    _vm._m(22),
    _c("h3", [_vm._v("十、免责声明")]),
    _vm._m(23),
    _vm._m(24),
    _vm._m(25),
    _vm._m(26),
    _vm._m(27),
    _vm._m(28),
    _c("p", [
      _vm._v(
        " 3. 您理解并同意，在使用无限立方中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力给用户造成的损失，用户同意公司不承担责任。 "
      )
    ]),
    _vm._m(29),
    _vm._m(30),
    _vm._m(31),
    _vm._m(32),
    _c("p", [
      _vm._v(
        " 8. 您明确了解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，无限立方在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。 "
      )
    ]),
    _c("h3", [_vm._v("十一、无限立方的有限责任")]),
    _vm._m(33),
    _vm._m(34),
    _vm._m(35),
    _vm._m(36),
    _c("h3", [_vm._v("十二、发票")]),
    _c("p", [
      _vm._v(
        " 1. 无限立方是本软件及服务的知识产权权利人。本软件的一切著作权、专利权、商标权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、字体、音频、视频、界面设计、版面框架、电子文档等）均受中华人民共和国《著作权法》及相关法律法规和相应的国际条约保护。无限立方享有上述知识产权，依法属于他人的除外。 "
      )
    ]),
    _vm._m(37),
    _vm._m(38),
    _vm._m(39),
    _c("h3", [_vm._v("十三、知识产权声明")]),
    _c("p", [
      _vm._v(
        " 1. 发票是用户证明自己购买付费服务或其他服务的重要凭证，无限立方鼓励用户在购买付费服务或其他服务后及时向无限立方订单中心申请发票。用户须向无限立方提供开具发票所必需的信息（如姓名/组织名称、身份证号/统一社会信用代码等）。例外地，如用户是通过无限立方的合作伙伴等渠道购买的付费服务或其他服务，应向该合作伙伴索取发票。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 2. 无限立方向有需要的用户提供由国家税务机关统一发放的与纸质发票同等法律效力的电子发票。需要纸质发票的用户请单独与无限立方客服联系并须自付邮寄费用，纸质发票遗失不补。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 3. 因用户填写错误造成发票信息需要更正的，用户最迟应于发票开出当月的最后一个工作日的工作时间（北京时间9点至12点）向无限立方申请更正并提供正确的信息。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 4. 如发生服务或商品的退货、退款，已开具纸质发票的用户须先将原发票退回无限立方并承担邮寄费用，否则无法办理退款；已开具电子发票的用户，由于发生退货、退款时无限立方会将发票进行红冲，用户不能再将该电子发票用于报销或入账，因使用作废发票报销导致的一切后果用户本人自行承担。 "
      )
    ]),
    _c("h3", [_vm._v("十四、投诉方式及处理")]),
    _c("p", [
      _vm._v(
        " 1. 如您发现无限立方平台内存在任何侵犯您权利的内容，请立即通过客服通知无限立方，提供您有相关权利的初步证据，无限立方将会根据法律规定及时处理您的投诉。如您在使用无限立方制作的内容被相关权利人投诉侵权，您也可以通过向无限立方提交反通知书及相关权利证明材料，来进行申诉，公司同样会根据法律规定及时处理您的申诉。 "
      )
    ]),
    _vm._m(40),
    _c("h3", [_vm._v("十五、其他条款")]),
    _vm._m(41),
    _c("p", [
      _vm._v(
        " 2. 除非本协议另有其它明示规定，无限立方增加或强化目前任何新功能，包括所推出的新产品，均受到本使用协议之规范。 "
      )
    ]),
    _c("p", [
      _vm._v(
        " 3. 本协议的成立、生效、履行、解释及争议解决均适用中华人民共和国的法律。 "
      )
    ]),
    _vm._m(42),
    _c("p", [
      _vm._v(
        " 5. 无限立方对于您所有的通知均可通过网页公告、页面提示、弹窗、消息通知、公众号通知、站内信、您预留的联系方式（电子邮件、手机短信等）等一种多种方 式进行通知，该通知自无限立方发送之时视为已完成送达。如以多种方式通知，则送达时间以最早送达之时为准。 "
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 为使用无限立方平台及服务，您应当仔细阅读并遵守《无限立方用户服务协议》（以下简称"
      ),
      _c("strong", [_vm._v("“本协议”")]),
      _vm._v(
        "以及《无限立方隐私政策》，特别是免除或者限制责任的以及其他涉及您重大权益的条款，这些条款可能以"
      ),
      _c("strong", [_vm._v("加粗加黑")]),
      _vm._v("方式提示您重点注意。"),
      _c("strong", [
        _vm._v(
          "您在本平台上注册、登录、使用即视为您已充分阅读并完全自愿接受本用户服务协议的各项内容，包括无限立方对本协议随时所做的任何修改，并成为无限立方用户。如果您未满18周岁，请在法定监护人的陪同下阅读本协议及其他上述协议。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 2.服务主体：本服务由无限立方为您提供。“无限立方”是深圳市太和物联信息技术有限公司（下称"
      ),
      _c("strong", [_vm._v("“公司”")]),
      _vm._v(
        "）运营的网络平台，本协议项下的“无限立方”根据不同的语境可能指无限立方网页、移动端及围绕无限立方软件产品提供的相关技术服务。“用户”是指注册、登录、使用无限立方及服务的使用人，在本协议中更多地称呼为“您”。 "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" （1） 无限立方赋予您一项"),
      _c("strong", [_vm._v("非排他性的、非独家的、不可转让的、不可转许可")]),
      _vm._v("的授权，以使用本软件。 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 1.5 用户有权主动注销自己的无限立方账号，但注销后无限立方不退还已收取的任何费用和收费。"
      ),
      _c("strong", [
        _vm._v(
          "特别地，用户同意，无论是用户主动注销账号或由于其他原因导致无限立方删除用户的账号，注销账号并不意味着无限立方免除用户应向无限立方支付而未支付的费用，也不意味着免除用户其他的法律责任；为遵从国家法律法规要求、内容审计或追偿等目的，无限立方将依法留存用户账号相关的日志信息。您理解并同意，用户注销账号或由于其他原因导致无限立方删除用户账号的，用户与无限立方在服务协议中关于知识产权、违约责任、法律适用和争议解决方式等约定并不随账号的注销或删除而终止。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " （4）依照《隐私策略》或其他协议规则可以转移或披露给第三方的情形。 "
      ),
      _c("strong", [
        _vm._v(
          "无限立方非常重视对未成年人信息保护，若您不具备完全民事行为能力，在使用无限立方前，应事先取得您的监护人同意。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 1.2 您理解并同意，无限立方一直致力于为用户提供文明健康、规范有序的网络环境，"
      ),
      _c("strong", [
        _vm._v(
          "您不得利用无限立方制作、复制、发布、传播任何违法违规内容，包括但不限于："
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 1.3.1 若您将从无限立方平台下载的图片、模板、文字、海报等内容"),
      _c("strong", [_vm._v("用于广告内容，不得有下列情形：")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 1.3.2 若您将从无限立方平台上下载的图片、模板、文字、海报等内容"),
      _c("strong", [
        _vm._v("用于商标、企业名称、商品名称或logo等，不得有下列情形：")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 1.3.6"),
      _c("strong", [
        _vm._v(
          "无限立方所提供的素材为免费与收费两部分，免费素材仅供学习交流使用，不允许商业使用。若为商业用途，需付费。平台提示有版权风险的内容，需替换或自行联系权利人获取相关内容的授权。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 1.3.7"),
      _c("strong", [
        _vm._v(
          " 非经无限立方书面允许，您不得将无限立方上的图片、模板、文字、LOGO等注册成商标或申请外观设计专利，如您由此需求，您可以联系无限立方进行专属定制或获得专门授权"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 2.1您同意按照本协议约定的方式合理合法地使用无限立方，除非法律允许或无限立方书面许可，您通过无限立方观看、分享、下载、使用、传播、发布的任何图片、视频及其他内容，仅限您个人使用，您不得有偿或无偿转让依本协议条款所取得的用户权利，且"
      ),
      _c("strong", [_vm._v("不得从事下列行为")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 1."),
      _c("strong", [
        _vm._v(
          "如果无限立方发现或收到他人举报投诉您违反本协议或存在任何恶意行为的，无限立方有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号除以包括但不限于警告、限制或禁止使用全部或部分功能、账号封禁、注销等处罚，并公告处理结果。无限立方有权对涉嫌违反法律法规、涉嫌违法犯罪的行为保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。对已删除内容公司有权不予返还。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 2. "),
      _c("strong", [
        _vm._v(
          "因您违反本协议或其他条款规定，引起第三方投诉或诉讼索赔的，您应当自行承担全部法律责任。因您的违法或违约行为导致无限立方及其关联公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿无限立方及其关联公司因此遭受的全部损失。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 3. 公司尊重并保护法人、公民的知识产权、名誉权、姓名权、隐私权等合法权益。"
      ),
      _c("strong", [
        _vm._v(
          "您保证，在使用无限立方上传的文字、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，无限立方有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行承担全部法律责任；如因您的侵权行为导致无限立方及其关联公司遭受损失的（包括但不限于经济、商誉等损失），您还应足额赔偿无限立方及其关联公司遭受的全部损失。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 1."),
      _c("strong", [
        _vm._v(
          "您理解并同意，无限立方有权于任何时间暂时或永久修改或终止本服务（或其任何部分），且无论通知与否。您同意对于本服务所作的任何修改、暂停或终止，无限立方对您和任何第三人均无需承担任何责任，您应妥善做好数据更新、备份与转移工作。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 2."),
      _c("strong", [
        _vm._v(
          " 因您违反本协议，无限立方有权根据实际情况中断或终止其中一项或多项服务，严重违反的，无限立方将直接终止您对无限立方的使用权。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " （2）通知无限立方注销您的账号。账号注销将导致您在无限立方上的个人信息、作图记录，设计细节等一并删除，您和无限立方相应的权利义务随之终止（您的任何损害赔偿义务不会因此而终止），"
      ),
      _c("strong", [
        _vm._v("无限立方不承担传送任何未处理的信息或未完成的服务给您的责任。")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 1."),
      _c("strong", [
        _vm._v(
          "您在使用本软件中由第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。如因您使用由第三方提供的产品或服务时引发的任何纠纷，应由您和第三方负责解决，无限立方不承担任何责任。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 2. 您在使用本软件中由第三方提供的产品或服务时，本软件可能会调用第三方系统或者通过第三方支持用户的使用或访问，使用或访问的结果由该第三方提供，无限立方不保证通过第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，"
      ),
      _c("strong", [
        _vm._v(
          "由此若引发的任何争议及损害，与无限立方无关，无限立方不承担任何责任。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 1. 无限立方中可能包含广告，这属于无限立方及其关联公司合理合法的商业模式，"
      ),
      _c("strong", [
        _vm._v(
          "您同意在使用无限立方过程中接收由无限立方及其关联公司或者其第三方合作伙伴发布的广告信息或其他信息（包括商业与非商业信息），信息的具体发送及展示形式、频次及内容等以无限立方实际提供为准。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 2. 您通过本网站与广告商及其他第三方进行任何形式的通讯或商业往来，或参与促销活动，包含相关商品或服务之付款及交付，以及达成的其它任何相关条款、条件、保证或声明，完全为您与广告商及其他第三方之间之行为。您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，"
      ),
      _c("strong", [
        _vm._v(
          "您因前述任何交易或前述广告商及其他第三方而遭受的任何性质的损失或损害，无限立方对此不负任何法律责任。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 1. 如您使用收费服务，请遵守相关的协议。若您不同意相关协议及其修改和变更，请暂停使用增值服务。"
      ),
      _c("strong", [
        _vm._v(
          "若您为未满18周岁的未成年人，请您在法定监护人陪同下，认真仔细阅读增值服务相关协议并选择是否接受该协议及其服务。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 2."),
      _c("strong", [
        _vm._v(
          "在无限立方降低增值服务的收费标准或者将收费服务改为免费服务提供时，无限立方保留不对原付费用户提供退费或者费用调整的权利。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 1. 您理解并同意，无限立方软件及相关服务可能会受多种因素的影响或干扰，因此"
      ),
      _c("strong", [_vm._v("无限立方不保证(包括但不限于)：")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("（1）完全适合用户的使用要求；")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("（2）不受干扰，及时、安全、可靠或不出现错误；")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v(
          "（3）用户经由无限立方取得的任何软件、服务或其他材料符合用户的期望；"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("（4）软件中任何错误都将能得到更正。")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 2. 对于涉嫌借款或其他涉财产的网络信息、账户密码、广告或推广等信息的，请您谨慎对待并自行进行判断，"
      ),
      _c("strong", [
        _vm._v(
          "基于前述原因您因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，无限立方不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 4. "),
      _c("strong", [
        _vm._v(
          " 无限立方将依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 5. 您明确了解并同意："),
      _c("strong", [
        _vm._v(
          "关于本协议服务，无限立方不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对本协议软件及相关服务的使用行为必须自行承担相应风险。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 6. "),
      _c("strong", [
        _vm._v(
          " 您明确了解并同意，您使用本服务之风险由您个人负担，包括但不限于因任何资料之下载而导致的您手机之任何损坏或数据流失；您将文字、资讯、资料、音乐、照片、图形、视讯、信息或其它个人资料上传到互联网上，而被其他组织或个人复制、转载、或做其它用途等。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 7."),
      _c("strong", [
        _vm._v(
          "您明确了解并同意，本服务仅依其当前所呈现的状况提供，对于任何用户信息或个人化设定之时效、删除、传递错误、未予储存或其它任何问题，无限立方均不承担任何责任。无限立方保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 2. "),
      _c("strong", [
        _vm._v(
          "您明确了解并同意，无限立方作为网络服务提供者，无法逐一审查平台中各类图片、素材、模板等内容的真实性、合法性。据此，无限立方再次郑重提请您注意，任何经由本服务而发布、上传的文字、图片、素材、模板等，均由内容提供者承担责任。无限立方仅为您提供内容存储空间，无法控制经由本服务传送之内容，亦不保证内容的真实性、合法性，对此请您谨慎判断。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 3. "),
      _c("strong", [
        _vm._v(
          "您明确了解并同意，无限立方无法审查和跟踪您使用上述内容的用途。据此，您超出本协议约定或无限立方授权的其他范围使用内容，导致的第三方追责或您自身的损失，由您自行承担。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 4. "),
      _c("strong", [
        _vm._v(
          "您明确了解并同意，本协议下，无限立方赔偿限额为无限立方实际收到您支付的费用，如字体授权许可费、会员费用等，这是无限立方对任一用户所承担的最高义务及责任总额。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 2. "),
      _c("strong", [
        _vm._v(
          "未经无限立方书面同意，您不得为任何商业或非商业目的自行或许可第三方实施、利用、转让上述知识产权，无限立方保留追究上述行为法律责任的权利。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 3. 您理解并同意，您在使用无限立方平台及相关服务时发布上传的文字、图片、视频、音频等均由您原创或已获合法授权（含转授权）。您通过无限立方制作内容的知识产权归您或原始著作权人所有， "
      ),
      _c("strong", [
        _vm._v(
          "但制作内容中您所使用的本平台内各种素材的著作权仍归无限立方或实际权利人享有。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 4. "),
      _c("strong", [
        _vm._v(
          "您同意授予无限立方一项为提供、优化和宣传推广本服务之目的所必须的免费、非独占、可在全球范围内收集、使用、存储、管理和保护您在本服务过程中产生的用户内容的权利"
        )
      ]),
      _vm._v(
        "。 无限立方将根据《隐私政策》并尽最大努力在合理范围内使用用户内容，且您的必要授权与同意并不代表无限立方对您在本服务过程中产生内容的必然使用，也不改变您对用户内容的所有权及其知识产权归属，更不影响您对用户内容任何合法使用。 "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " 2. 无限立方倡议您共同参与维护及监督无限立方使用环境的合规与清洁。 "
      ),
      _c("strong", [
        _vm._v(
          "无论无限立方公司主动发现您的不当、违法行为，或者是无限立方根据举报、投诉并初步查实您存在不当、违法行为，无限立方均有权立即在无事先通知的情况下采取必要的措施以制止该等行为的继续进行。"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 1. "),
      _c("strong", [
        _vm._v(
          "无限立方有权依据国家政策、技术条件、产品功能等变化需要而进行修改本协议，并将修改后的协议予以发布。本协议条款变更后，如果您继续使用无限立方，即视为您已接受修改后的协议。 "
        )
      ]),
      _vm._v(
        " 本协议内容同时包括无限立方已经发布及后续可能不断发布的相关协议、规则等内容（包括但不限于《隐私政策》）。前述内容已经发布，公司将以适当的方式（网站公布、系统通知等）提醒您及时阅读。前述相关协议、规则构成本协议不可分割的组成部分，您应同样遵守。 "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" 4. "),
      _c("strong", [
        _vm._v(
          " 本协议的签署地点为中华人民共和国深圳市南山区，若您与无限立方发生争议，应尽量友好协商解决，协商不成，您同意将该争议提交至本合同签订地有管辖权的人民法院管辖。"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }